import React from 'react';

import Helmet from 'components/Helmet';
import styles from './index.module.scss';
import { graphql } from 'gatsby';
import { SlideUp } from '../components/Transition';
import Img from 'gatsby-image';

const IndexPage = ({ data: { file } }) => {
  return (
    <div className={styles.landingPageRoot}>
      <Helmet />
      <SlideUp className={styles.transitionWrapper}>
        <div className={styles.content}>
          <div className={styles.text}>
            <span>Coming Soon</span>
          </div>
          <div className={styles.logo}>
            <Img fluid={file.childImageSharp.fluid} />
          </div>
        </div>
      </SlideUp>
    </div>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query LandingPageQuery {
    file(relativePath: { eq: "logo-color.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
